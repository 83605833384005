@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "FRUTIGER";
    src: url("../public/Frutiger.ttf")
}

body {
    --primary: #367ee9;
    --secondary: #a0aec0;
    --accent: #718096;
    --background: #fff;
}

.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-image: url('../public/slider.png');
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 7px;

}

@media screen and (orientation: portrait) {
    .gutter {
        background-image: url('../public/slider_horizontal.png');
    }
}

.gutter.gutter-horizontal {
    cursor: col-resize;
}

.threesixty > .labels {
    position: absolute;
    left: 0;
    top: 0;
}

.threesixty > .labels > div {
    position: absolute;
    left: 0;
    top: 0;
}

.threesixty > .labels > div:hover {
    color: var(--accent);
}

.slider-thumb {
    @apply bg-systempiloten_gray appearance-none rounded-full;
}

.slider-thumb::-webkit-slider-thumb {
    @apply appearance-none w-3 h-3 rounded-full bg-primary cursor-pointer;
}

.slider-thumb::-moz-range-thumb {
    @apply appearance-none w-3 h-3 rounded-full bg-primary cursor-pointer;
}

.mask-squircle {
    -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==);
    mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==)
}

.mask {
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}

.indicator {
    position: relative;
    display: inline-flex;
    width: max-content;
}

.indicator :where(.indicator-item) {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    bottom: auto;
    transform: translate(50%, -50%);
}

